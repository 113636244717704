/* eslint-disable no-console */
const luxon = require('luxon');
// const Transport = require("@ledgerhq/hw-transport-node-hid").default;

// const { ledgerService } = require('@ledgerhq/hw-app-eth/lib/Eth');
const ConstantsCommon = require('../ConstantsCommon');
const UtilsCommonMin = require('../misc/UtilsCommonMin');

// const _hidFraming = require('../../node_modules/@ledgerhq/devices/lib/hid-framing.js').default;
const Transport = require('@ledgerhq/hw-transport-webusb').default;
// import Transport from "@ledgerhq/react-native-hw-transport-ble";
const AppEth = require('@ledgerhq/hw-app-eth').default;
// const ledgerService = require('@ledgerhq/hw-app-eth').ledgerService;
// const listen = require('@ledgerhq/logs').listen;

const derivationPath = '44\'/60\'/0\'/0/0';

const linkLedger = async (displayModal = false) => {
    const addressEthMmSource = 'ledger';
    const errMsg = 'Failed to link Ledger address';
    const successMsg = 'Ledger address linked successfully';
    const elem = '.button-metamask-connect, .button-ledger-connect, .button-bbwallet-connect, .button-bblwallet-connect';
    let start = luxon.DateTime.now().valueOf();
    try {
        window.hideToast();
        $(elem).attr('disabled', true);
        const usbSupported = await Transport.isSupported();
        if (!usbSupported) {
            $(elem).attr('disabled', false);
            return;
        }

        if (displayModal) {
            $('#modal-ledger').modal('show');
            $('#button-ledger-ok').attr('disabled', true);
            $('#button-ledger-retry').attr('disabled', true);
            $('#button-ledger-retry').attr('onclick', 'bobbobLedger.linkLedger(true);return false;');
            $('#modal-ledger-message').html('Connecting to ledger device...');
        }
        const transport = await Transport.create();
        const ledgerAppEthTmp = new AppEth(transport);
        const result = await ledgerAppEthTmp.getAddress(derivationPath);
        console.log(`${window.logPrefix()}result [${JSON.stringify(result)}]`);
        const addressEthMm = result.address.toLowerCase();

        if (addressEthMm === balanceManager.userAddressEthMm && balanceManager.userAddressEthMmSource === 'ledger') {
            const msg = '<div>The wallet address is already linked, please select another address in your wallet and tap Link Ledger Wallet again.</div>';
            $('#button-proceed-anyway').hide();
            $('#notice-msg').html(msg);
            $('#button-notice').attr('onclick', '$(\'#modal-notice\').modal(\'hide\');');
            $('#modal-notice').modal('show');
            $(elem).attr('disabled', false);
            if (displayModal) {
                $('#modal-ledger').modal('hide');
            }
            return;
        }
        if (displayModal) {
            // start = await checkElapsedSleep(start);
            // $('#modal-ledger-message').html('Getting auth data...');
        }
        const {
            data: dataAuth,
            domainHash,
            messageHash,
        } = await bobbobAuth.Web3Helper.getEip712AuthData(addressEthMm, 'add-wallet');
        const hash = dataAuth.message.challenge;
        const deadline = dataAuth.message.deadline;
        const sig = await signEip712Message(dataAuth, domainHash, messageHash, displayModal, ledgerAppEthTmp);

        if (displayModal) {
            start = await bobbob.HtmlHelper.checkElapsedSleep(start);
            $('#modal-ledger-message').html('Linking ledger device...');
        }
        // $('.button-metamask-connect').attr('disabled', true);
        const accessTokenToUse = await authManager.checkRefreshGetAccessTokenAsync();
        const payload = {
            addressEthMm,
            addressEthMmSource,
            hash,
            deadline,
            sig,
        };
        window.onTx();
        const response = await fetch(
            `${ConstantsCommon.API_URL}/wallet/metamask`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessTokenToUse}`,
                },
                body: JSON.stringify(payload),
            },
        );
        window.onRx();
        const statusCode = response.status;
        console.log(`${window.logPrefix()}linkLedger statusCode [${statusCode}]`);
        if (statusCode === 200) {
            const {
                accessTokenInfoRefreshToken,
                avatarUpdateInfo,
                userBalanceData,
            } = await response.json();
            const authInfo = {
                address: addressEthMm,
                addressSource: addressEthMmSource,
                hash,
                deadline,
                sig,
                // hasWeb2: accessTokenInfoRefreshToken.idTokenInfo.hasWeb2,
            };
            authManager.processAccessTokenInfoRefreshToken(authInfo, accessTokenInfoRefreshToken);
            getAddressSourceFriendlyName(addressEthMmSource, true);

            window.web3Metamask = undefined;
            window.ledgerAppEth = ledgerAppEthTmp;
            // $('#address-eth-mm-source').html('Ledger');
            // $('#address-eth-mm').html(getAddressTemplate(addressEthMm, 'address-eth-mm'));
            balanceManager.userAddressEthMm = addressEthMm;
            balanceManager.userAddressEthMmSource = addressEthMmSource;
            $('#top-button-ledger-connect').hide();
            // $('#default-to-custodial').prop('checked', false).trigger('change');
            $('#default-to-custodial').prop('checked', false);
            balanceManager.processBalanceData(userBalanceData);

            processProfileAvatar(avatarUpdateInfo);
            window.showToast(successMsg, true);
            $(elem).attr('disabled', false);

            if (displayModal) {
                start = await bobbob.HtmlHelper.checkElapsedSleep(start);
                $('#modal-ledger').modal('hide');
            }
        } else if (statusCode === 401) {
            $(elem).attr('disabled', false);
            authManager.handle401Redirect();
        } else {
            $(elem).attr('disabled', false);
            const errMsgFinal = await authManager.showDataErrorToast(response, errMsg);
            if (displayModal) {
                start = await bobbob.HtmlHelper.checkElapsedSleep(start);
                $('#modal-ledger-message').html(errMsgFinal);
                $('#button-ledger-ok').attr('disabled', false);
                $('#button-ledger-retry').attr('disabled', false);
            }
        }
    } catch (error) {
        $(elem).attr('disabled', false);
        console.log(`${window.logPrefix()}${errMsg}: ${error.stack || error.message}`);
        window.showToast(`${errMsg}: ${error.message}`);
        if (displayModal) {
            await bobbob.HtmlHelper.checkElapsedSleep(start);
            $('#modal-ledger-message').html(`${errMsg}: ${error.message}`);
            $('#button-ledger-ok').attr('disabled', false);
            $('#button-ledger-retry').attr('disabled', false);
        }
    }
};

const sendTxnAndTrack = async (txnHexBase, decodedData, rawTxnInfo, simOutputIn) => {
    const errMsg = 'Failed to send Ledger txn';
    const successMsg = 'Ledger txn sent successfully';
    let start = luxon.DateTime.now().valueOf();
    let elapsed = 0;
    try {
        window.hideToast();
        $('#modal-ledger').modal('show');
        $('#button-ledger-ok').attr('disabled', true);
        $('#button-ledger-retry').attr('disabled', true);
        $('#button-ledger-retry').attr('onclick',
            `bobbobLedger.sendTxnAndTrack(
            JSON.parse(decodeURIComponent('${encodeURIComponent(JSON.stringify(txnHexBase))}')),
            ${decodedData ? `JSON.parse(decodeURIComponent('${encodeURIComponent(JSON.stringify(decodedData))}'))` : 'undefined'},
            ${rawTxnInfo ? `JSON.parse(decodeURIComponent('${encodeURIComponent(JSON.stringify(rawTxnInfo))}'))` : 'undefined'},
            ${simOutputIn ? `JSON.parse(decodeURIComponent('${encodeURIComponent(JSON.stringify(simOutputIn))}'))` : 'undefined'},
        );return false;`);
        $('#modal-ledger-message').html('Preparing transaction...');
        let baseFeePerGas;
        let txnHex;
        let simOutput;
        let unsignedTransactionHex;
        if (rawTxnInfo) {
            baseFeePerGas = rawTxnInfo.baseFeePerGas;
            unsignedTransactionHex = rawTxnInfo.unsignedTransactionHex;
            txnHex = rawTxnInfo.txnHex;
            simOutput = simOutputIn;
        } else {
            const estimatedGasFactorBp = $('#estimated-gas-factor').val();
            ({
                baseFeePerGas,
                txnHex,
                unsignedTransactionHex,
                simOutput,
            } = await bobbobAuth.Web3Helper.getRawTxn(txnHexBase, true, estimatedGasFactorBp));
        }
        const unsignedTransactionHexNoHex = web3.utils.stripHexPrefix(unsignedTransactionHex);

        // const resolution = { "nfts":[],"erc20Tokens":[],"externalPlugin":[],"plugin":[],"domains":[] };
        // const resolution = await ledgerService.resolveTransaction(
        //     unsignedTransactionHexNoHex,
        //     window.ledgerAppEth.loadConfig,
        //     {
        //         externalPlugins: false,
        //         erc20: false,
        //         nft: false,
        //     },
        // );
        // console.log(`${window.logPrefix()}resolution [${JSON.stringify(resolution)}]`);
        start = await bobbob.HtmlHelper.checkElapsedSleep(start);
        // TODO: display sim output like in bbwallet
        const msg = bobbob.HtmlHelper.getTxnSimOutputHtml('ledger-wallet', 'Waiting for user to sign transaction...', txnHex, simOutput, '', true);
        $('#modal-ledger-message').html(msg);
        // render after html added (NOTE: not updated in realtime because msg already sent to device for signing)
        bobbob.HtmlHelper.updateGasIndicator('ledger-wallet');
        bobbob.HtmlHelper.updateTxnFees('ledger-wallet', txnHex, baseFeePerGas, txnHex.maxPriorityFeePerGas, txnHex.maxFeePerGas);

        const rsv = await window.ledgerAppEth.signTransaction(derivationPath, unsignedTransactionHexNoHex/* , resolution */);
        console.log(`${window.logPrefix()}rsv [${JSON.stringify(rsv)}]`);
        const sig = UtilsCommonMin.getSignatureFromRsvLedgerSignTxn(rsv);
        console.log(`${window.logPrefix()}sig [${sig}]`);
        const rsvCleansed = UtilsCommonMin.getRsvFromSignature(web3, sig);
        // txnHex.r = `0x${rsv.r}`;
        // txnHex.s = `0x${rsv.s}`;
        // const v = Number.parseInt(rsv.v, 16);
        // txnHex.v = `${v}`;
        txnHex.r = rsvCleansed.r;
        txnHex.s = rsvCleansed.s;
        txnHex.v = rsvCleansed.v;

        start = luxon.DateTime.now().valueOf();
        $('#modal-ledger-message').html('Sending transaction...');
        // $('.button-metamask-connect').attr('disabled', true);
        const accessTokenToUse = await authManager.checkRefreshGetAccessTokenAsync();
        const payload = {
            txnHexSigned: txnHex,
            decodedData,
        };
        window.onTx();
        const response = await fetch(
            `${ConstantsCommon.API_URL}/txn/sendtrack`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessTokenToUse}`,
                },
                body: JSON.stringify(payload),
            },
        );
        window.onRx();
        const statusCode = response.status;
        console.log(`${window.logPrefix()}sendTxnAndTrack statusCode [${statusCode}]`);
        if (statusCode === 200) {
            const data = await response.json();
            const txnInfo = data.txnInfo;
            window.txnManager.upsertTxnConfirmation(txnInfo);
            // window.showToast(successMsg, true);
            $('#modal-ledger-message').html(successMsg);
            $('#button-ledger-ok').attr('disabled', false);
            start = await bobbob.HtmlHelper.checkElapsedSleep(start);
            $('#modal-ledger').modal('hide');
        } else if (statusCode === 401) {
            authManager.handle401Redirect();
        } else {
            const errMsgFinal = await authManager.showDataErrorToast(response, errMsg, true);
            start = await bobbob.HtmlHelper.checkElapsedSleep(start);
            $('#modal-ledger-message').html(errMsgFinal);
            $('#button-ledger-ok').attr('disabled', false);
            $('#button-ledger-retry').attr('disabled', false);
        }
    } catch (error) {
        console.log(`${window.logPrefix()}${errMsg}: ${error.stack || error.message}`);
        await bobbob.HtmlHelper.checkElapsedSleep(start);
        // window.showToast(errMsg);
        if (error.name === 'DisconnectedDeviceDuringOperation') {
            $('#modal-ledger-message').html('Ledger device disconnected, please reconnect wallet and retry');
            window.ledgerAppEth = undefined;
            $('#top-button-ledger-connect').show();
        } else {
            $('#modal-ledger-message').html(`${errMsg}: ${error.message}`);
            $('#button-ledger-retry').attr('disabled', false);
        }
        $('#button-ledger-ok').attr('disabled', false);
    }
};

const displayErrorMessage = (signIn, msg) => {
    if (signIn) {
        bobbobAuth.setHtmlShowHide('#login-msg', msg);
    } else {
        window.showToast(msg, false);
    }
};

const signEip712Message = async (data, domainHash, messageHash, displayModal = false, ledgerAppEth = undefined) => {
    const ledgerAppEthToUse = ledgerAppEth || window.ledgerAppEth;
    const isModalOpen = $('#modal-ledger').hasClass('in') || $('#modal-ledger').hasClass('show');
    const start = luxon.DateTime.now().valueOf();
    if (displayModal) {
        if (!isModalOpen) {
            $('#modal-ledger').modal('show');
            $('#button-ledger-ok').attr('disabled', true);
            $('#button-ledger-retry').attr('disabled', true);
            $('#button-ledger-retry').attr('onclick',
                `bobbobLedger.signEip712Message(
                JSON.parse(decodeURIComponent('${encodeURIComponent(JSON.stringify(data))}')),
                '${domainHash}', '${messageHash}', true);return false;`);
        }
        const msg = bobbob.HtmlHelper.getSignEip712Html('', 'Waiting for user to sign auth message...', data);
        $('#modal-ledger-message').html(msg);
    }
    try {
        let rsv;
        try {
            rsv = await ledgerAppEthToUse.signEIP712Message(derivationPath, data);
        } catch (error) {
            console.log(`${window.logPrefix()}Failed to signEIP712Message, falling back to signEIP712HashedMessage: [${error.stack || error.message}]`);
            // const domainSeparatorHex = Buffer.from(domainHash).toString('hex');
            // const hashStructMessageHex = Buffer.from(messageHash).toString('hex');
            rsv = await ledgerAppEthToUse.signEIP712HashedMessage(derivationPath, domainHash, messageHash);
        }
        console.log(`${window.logPrefix()}rsv [${JSON.stringify(rsv)}]`);
        const sig = UtilsCommonMin.getSignatureFromRsvLedgerSignMsg(rsv);
        console.log(`${window.logPrefix()}sig [${sig}]`);
        if (displayModal) {
            if (!isModalOpen) {
                $('#modal-ledger').modal('hide');
            }
        }
        return sig;
    } catch (error2) {
        if (displayModal) {
            if (!isModalOpen) {
                await bobbob.HtmlHelper.checkElapsedSleep(start);
                $('#modal-ledger').modal('hide');
            }
        }
        throw error2;
    }
};

const connectLedger = async (event, elem, signIn, hideSuccess) => {
    $(elem).attr('disabled', true);
    bobbobAuth.setHtmlShowHide('#login-msg', 'Connecting to Ledger device...');
    const usbSupported = await Transport.isSupported();
    if (!usbSupported) {
        $(elem).attr('disabled', false);
        // bobbobAuth.setHtmlShowHide('#login-msg', 'USB supported unavailable, cannot connect to Ledger device');
        displayErrorMessage(signIn, 'USB supported unavailable, cannot connect to Ledger device');
        return null;
    }
    try {
        const transport = await Transport.create();
        window.ledgerAppEth = new AppEth(transport);
        const result = await window.ledgerAppEth.getAddress(derivationPath);
        console.log(`${window.logPrefix()}result [${JSON.stringify(result)}]`);
        // bobbobAuth.setHtmlShowHide('#login-msg', result.address);
        let account;
        let success;
        if (signIn) {
            const simError = false;
            if (simError) {
                throw new Error('SIM ERROR');
            }
            account = result.address.toLowerCase();
            const accessTokenInfoRefreshToken = await authManager.signinUsingMetamask(
                account,
                false,
                false,
                async (_from, data, domainHash, messageHash) => {
                    // console.log(`${window.logPrefix()}data [${JSON.stringify(data, null, 4)}]`);
                    const msg = bobbob.HtmlHelper.getSignEip712Html('', 'Waiting for user to sign auth message...', data);
                    bobbobAuth.setHtmlShowHide('#login-msg', msg);
                    const sig = await signEip712Message(data, domainHash, messageHash);
                    return sig;
                },
                'ledger',
            );
            success = !!accessTokenInfoRefreshToken;
            // don't need to enable elem if successful, since we will be redirecting to homepage
            if (!success) {
                $(elem).attr('disabled', false);
            }
        } else {
            // allow relinking if address is different, eg user has multiple ledger wallets
            const allowDifferentWalletLink = false;
            const allowDifferentWalletLinkPrompt = false;
            const { registeredAddress, registeredAddressSource } = authManager.getRegisteredAddressSource();
            // let registeredAddress;
            // let registeredAddressSource;
            // if (authManager.authInfo) {
            //     registeredAddress = authManager.authInfo.address;
            //     registeredAddressSource = authManager.authInfo.addressSource;
            //     console.log(`${window.logPrefix()}connectLedger authManager.authInfo.addressSource [${authManager.authInfo.addressSource}]`);
            // } else if (window.balanceManager.userAddressEthMm) {
            //     registeredAddress = window.balanceManager.userAddressEthMm;
            //     registeredAddressSource = window.balanceManager.userAddressEthMmSource;
            //     console.log(`${window.logPrefix()}connectLedger window.balanceManager.userAddressEthMmSource [${window.balanceManager.userAddressEthMmSource}]`);
            // }
            if (registeredAddress && registeredAddressSource === 'ledger' && !!window.balanceManager.userAddressEthMm) {
                // user has logged in previosuly, jwt is still valid
                console.log(`result.address [${result.address}] registeredAddress [${registeredAddress}]`);
                if (registeredAddress === result.address.toLowerCase()) {
                    account = result.address.toLowerCase();
                }
                if (!account) {
                    if (allowDifferentWalletLink) {
                        let okToProceed = true;
                        if (allowDifferentWalletLinkPrompt) {
                            const msg = `The wallet address does not match linked wallet.
                            Are you sure you want to link to the new wallet?`;
                            okToProceed = await bobbob.HtmlHelper.modalConfirmPromise(msg);
                        }
                        if (okToProceed) {
                            account = result.address.toLowerCase();
                            await linkLedger(true);
                            success = true;
                        } else {
                            success = false;
                        }
                    } else {
                        $('.modal').modal('hide');
                        $('#modal-logged-out-msg').html('The wallet address does not match linked wallet, please sign-in again.');
                        $('#modal-logged-out').modal('show');
                        success = false;
                    }
                } else {
                    // success = await authManager.reconnectMetaMaskAddress(account, hideSuccess);
                    if (!hideSuccess) {
                        const successMsg = 'Ledger device connected successfully';
                        showToast(successMsg, true);
                    }
                    success = true;
                }
            } else {
                // logged in via social media, need user to sign in via wallet
                // TODO: need time to think of this flow, for now, we disallow this code flow, it hide connect button
                // if logged in via social
                // keep this flow only for logging in,
                // for adding/changing walllet, do it via profile
                // account = accounts[0];
                // const accessTokenInfoRefreshToken = await authManager.signinUsingMetamask(account, false, false, true, async (from, data) => {
                //     const sig = await Web3Helper.metaMaskSignTypedDataV3Async(from, data);
                //     return sig;
                // });
                // success = !!accessTokenInfoRefreshToken;
                // throw new Error('Unsupported code flow');
                const doAddAddress = true;
                if (!doAddAddress) {
                    const msg = /* html */`
                        <div>There is no registered wallet address to connect to Ledger device</div>
                        <div>Tap <a href="#" onclick="
                                                    $('#modal-notice').modal('hide');
                                                    $('#tab_mya').tab('show');
                                                    $('#tab_mya-balance').tab('show');
                                                    $('#button-link-ledger')[0].scrollIntoView({ behavior: 'instant' });
                                                    bobbob.HtmlHelper.pulseElement('#button-link-ledger', '#button-link-ledger');
                                                    ">here to link your wallet address</a>
                        </div>
                    `;
                    $('#button-proceed-anyway').hide();
                    $('#notice-msg').html(msg);
                    $('#button-notice').attr('onclick', '$(\'#modal-notice\').modal(\'hide\');');
                    $('#modal-notice').modal('show');
                    success = false;
                } else {
                    await linkLedger(true);
                    success = true;
                }
            }
            // await linkLedger(true);
            // success = true;
            $(elem).attr('disabled', false);
        }
        if (!success) {
            window.ledgerAppEth = null;
            $('#top-button-ledger-connect').show();
        } else {
            $('#top-button-metamask-connect').hide();
            $('#top-button-metamask-link').hide();
            $('#top-button-ledger-connect').hide();
            $('#top-button-ledger-link').hide();
            $('#top-button-bbwallet-connect').hide();
            $('#top-button-bbwallet-link').hide();
            $('#top-button-bblwallet-connect').hide();
            $('#top-button-bblwallet-link').hide();
            bobbobAuth.setHtmlShowHide('#login-msg', '');
        }
        return account;
    } catch (error) {
        console.log(`${window.logPrefix()}Failed to connect to Ledger: [${error.stack || error.message}]`);
        let errMsg;
        // Unable to claim interface
        if (error.name === 'TransportInterfaceNotAvailable') {
            // bobbobAuth.setHtmlShowHide('#login-msg', 'Ledger device is being used by another application');
            errMsg = 'Ledger device is being used by another application';
        } else if (error.name === 'TransportOpenUserCancelled') {
            errMsg = 'No Ledger device selected';
        } else if (error.name === 'LockedDeviceError') {
            // bobbobAuth.setHtmlShowHide('#login-msg', 'Ledger device is locked');
            errMsg = 'Ledger device is locked';
        } else {
            // bobbobAuth.setHtmlShowHide('#login-msg', `Failed to connect to Ledger device [${error.stack || error.message}]`);
            errMsg = `Failed to connect to Ledger device: ${error.message}`;
        }
        // showToast(errMsg);
        // bobbobAuth.setHtmlShowHide('#login-msg', errMsg);
        displayErrorMessage(signIn, errMsg);
        $('#top-button-ledger-connect').show();
    }
    $(elem).attr('disabled', false);
    return null;
};
const bobbobLedger = {
    connectLedger,
    sendTxnAndTrack,
    signEip712Message,
    linkLedger,
};
if (typeof window !== 'undefined') {
    const target = window.bobbobLedger || {};
    window.bobbobLedger = Object.assign(target, bobbobLedger);
}
module.exports = bobbobLedger;
